import { FC } from 'react';

import { CircularProgressProps, CircularProgress as MuiCircularProgress, useTheme } from '@mui/material';
import { mergeSx } from '~/utils/mui';

const CircularProgress: FC<CircularProgressProps> = ({ sx, ...props }) => {
    const theme = useTheme();
    return (
        <MuiCircularProgress
            thickness={4}
            size={22}
            sx={mergeSx(
                {
                    color: theme.palette.text.primary,
                    '& .MuiCircularProgress-circle': {
                        strokeLinecap: 'round',
                    },
                },
                sx,
            )}
            {...props}
        />
    );
};

export default CircularProgress;
